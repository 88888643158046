.FriendShip-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: start;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

.FriendShip-img {
  position: relative;
  width: 240px;
  height: 150px;
  margin: 15px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15), 0 4px 6px rgba(0, 0, 0, 0.1);
  object-fit: cover;
  cursor: pointer;
  transition: box-shadow 0.3s ease;

  @media (max-width: 300px) {
    margin: 15px 0;
    width: 100%;
  }
}

.FriendShip-img:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.1);
}

.img__modal--container {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.img__modal {
  z-index: auto;
  max-width: 70%;
  max-height: 70%;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.blank-img {
  width: 240px;
  height: 1px;
  margin: 15px;
}
