.activity-container {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  height: auto;
  scroll-snap-type: y mandatory;
  -webkit-overflow-scrolling: touch;
  background-color: #ebf0ff;
  flex-wrap: wrap;

  @media (max-width: 800px) {
    height: auto;
    flex-direction: column;
  }
}

.activity-container h1 {
  width: 100vw;
  margin: 20px 0 30px 0;
  text-align: center;
  color: #11324d;
  font-size: 2.8rem;
  font-family: 'Inter';
}

.activity-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  width: 300px;
  height: 450px;
  margin: 5px;
  scroll-snap-align: center;

  @media (max-width: 1000px) {
    width: 30vw;
  }
  @media (max-width: 800px) {
    width: 60vw;
    margin-bottom: 50px;
  }
  @media (max-width: 600px) {
    width: 80vw;
  }
}

.activity-block a {
  text-decoration-line: none;
}

.activity-block__title {
  color: #11324d;
  font-size: 1.3rem;
  font-weight: 600;
  padding-left: 10px;
  text-align: left;
  display: flex;
  align-items: center;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
}

.activity-block__image--big {
  width: 100%;
  height: 44%;
  border-radius: 18px;
  object-fit: cover;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.activity-block__image--small {
  width: 48%;
  height: 44%;
  border-radius: 18px;
  object-fit: cover;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}
