.home-container {
  position: relative;
  z-index: 1;
  width: 100;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #c0ceff;
}

.home-container__message {
  position: absolute;
  top: 40%;
  left: 0;
  margin: 0 0 0 5%;
  color: white;
}

.home-container__message--sentence1-pc {
  font-size: 30px;
  font-weight: 700;

  @media (max-width: 700px) {
    font-size: 0px;
  }
}

.home-container__message--sentence1-mobile {
  margin-bottom: 5px;
  font-size: 0px;
  font-weight: 700;

  @media (max-width: 700px) {
    font-size: 25px;
  }
}

.home-container__message--sentence2 {
  font-size: 60px;
  font-weight: 700;

  @media (max-width: 700px) {
    margin-top: 5px;
    font-size: 40px;
  }
}
