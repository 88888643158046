.showcasemainMobile {
  background-image: linear-gradient(
    to right top,
    #f5f5f5,
    #f7f7f7,
    #f8f9f8,
    #fafafa,
    #fcfcfc,
    #f9f8fb,
    #f5f5fb,
    #f1f1fa,
    #e5e7f7,
    #d7ddf4,
    #c8d4f1,
    #b7cbee
  );
  padding: 25px;
  padding-top: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0;
  overflow-y: auto;
}

.showcasemainMobile * {
  font-family: 'Pretendard-Regular', 'Lora';
  font-family: 'RIDIBatang', 'Lora';
}

.showcasemainMobile-nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.showcasemainMobile-logo {
  margin-bottom: 10px;
}

.showcasemainMobile-logo a {
  text-align: left;
  font-size: 2.5rem;
  font-weight: 900;
  color: #333333;
  background-color: transparent;
  text-decoration: none;
}

.showcasemainMobile-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.showcasemainMobile-header .back-button {
  font-size: 1.8rem;
  color: #333333;
  cursor: pointer;
}

.ShowcasemainMobile-Dropdown {
  box-sizing: border-box;
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  font-weight: 500;
  color: black;
  background-color: whitesmoke;
  width: 28%;
  padding: 1.5% 2%;
  margin: 2vh 2.5% 0 2.5%;
  border: 1.5px solid #567fbb57;
  border-radius: 1vh;
  position: relative;
  z-index: 10;

  @media (max-width: 700px) {
    width: auto;
    padding: 1.5% 4%;
  }
}

.showcasemainMobile-Dropdown-top {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Dropdown-list {
  list-style-type: none;
  width: calc(100% + 2.67px);
  position: absolute;
  top: calc(100% + 2.5px);
  left: -1.3px;
  overflow-x: hidden;
  overflow-y: auto;
  background: whitesmoke;
  font-family: 'Lora';
  font-size: 1.1rem;
  border: 1.5px solid #567fbb57;
  border-radius: 1vh;

  & li {
    cursor: pointer;
    width: 100%;
    padding: 5% 3%;
    transition: all 0.3s ease;
    color: #333333;
    border-bottom: 1.5px solid #567fbb57;

    &:nth-last-child(1) {
      border-bottom: none;
    }

    &:hover {
      background-image: linear-gradient(to right, #29323c, #485563, #2b5876, #4e4376);
      color: whitesmoke;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.showcasemainMobile-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.showcasemainMobile-main button {
  margin: 8% 3%;
  padding: 1% 5%;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1.5px solid #333333;
  color: #333333;
  font-size: 1.3rem;
  font-weight: bold;
  transition: all 0.3s ease;
  background-color: white;
  box-shadow: 0 2px 8px 0 rgba(45, 54, 65, 0.5);
}

.showcasemainMobile-main button:hover {
  background-color: #333333;
  color: whitesmoke;
}

.showcasemainMobile-section1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  padding: 3%;
  border: 1px solid #0000001a;
  background-color: white;
}

.showcasemainMobile-gallery1 {
  padding: 3%;
  width: 95%;
  background-color: whitesmoke;
  aspect-ratio: 1.5/1;
  border: 1px solid #0c0b0b1a;
}

.showcasemainMobile-gallery1 img {
  width: 100%;
  aspect-ratio: 1.5/1;
  object-fit: cover;
}

.showcasemainMobile-section2 {
  width: 95%;
  height: 97vh;
  padding: 3%;
  border: 1px solid #0000001a;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
}

.section2-gallery1 {
  width: 60%;
  aspect-ratio: 1.5/1;
  z-index: 2;
  background: whitesmoke;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2%;
  position: absolute; /* 절대 위치로 설정하여 겹침 */
  border: 1px solid #0c0b0b1a;
}

.section2-gallery1 img {
  width: 100%;
  object-fit: cover;
}

.section2-text {
  z-index: 1;
  aspect-ratio: 1.5/1;
  background-color: whitesmoke;
  display: inline-flex;
  width: 60%;
  flex-direction: column;
  align-self: flex-end;
  justify-content: center;
  align-items: center;
  padding: 2%;
  position: absolute;
  top: 25vh;
  border: 1px solid #0c0b0b1a;
}
.section2-text p {
  width: 100%;
  text-align: center;
  text-justify: center;
  margin-top: 1%;
  color: white;
  background-color: black;
  text-decoration-line: underline;
  text-decoration-color: white;
  text-decoration-thickness: 1.3px;
  text-underline-offset: 3px;
  padding: 4px 6px;
  font-size: 2.2rem;
  font: bolder;
}
.section2-gallery2 {
  z-index: 2;
  width: 60%;
  top: 45vh;
  aspect-ratio: 1.5/1;
  background: whitesmoke;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2%;
  position: absolute; /* 절대 위치로 설정하여 겹침 */
  border: 1px solid #0c0b0b1a;
}
.section2-gallery2 img {
  width: 100%;
  object-fit: cover;
}
.section2-gallery3 {
  width: 60%;
  z-index: 1;
  top: 65vh;
  background: whitesmoke;
  border: 1px solid #0000001a;
  display: inline-flex;
  align-self: flex-end;
  justify-content: center;
  padding: 2%;
  position: absolute; /* 절대 위치로 설정하여 겹침 */
}
.section2-gallery3 img {
  width: 100%;
  object-fit: cover;
}

.showcasemainMobile-section3 {
  width: 95%;
  padding: 3%;
  border: 1px solid #0000001a;
  display: flex;
  justify-content: center;
  border: 1px solid #0c0b0b1a;
  background-color: white;
}

.showcasemainMobile-gallery3 {
  min-height: 200px;
  padding: 3%;
  width: 95%;
  aspect-ratio: 1.5/1;
  background: whitesmoke;
  border: 1px solid #0c0b0b1a;
}

.showcasemainMobile-gallery3 img {
  width: 100%;
  object-fit: cover;
}

@media (max-width: 900px) and (min-width: 800px) {
  .showcasemainMobile-section2 {
    height: 80vh;
  }
  .section2-gallery1 {
    width: 55%;
  }
  .section2-text {
    width: 50%;
    top: 50vh;
  }
  .section2-gallery2 {
    width: 55%;
    top: 100vh;
  }
  .section2-gallery3 {
    width: 55%;
    top: 150vh;
  }
}

@media (max-width: 900px) and (min-width: 800px) and (min-height: 1000px) {
  .showcasemainMobile-section2 {
    height: 75vh;
  }
  .section2-gallery1 {
    width: 55%;
  }
  .section2-text {
    min-width: none;
    width: 50%;
    top: 20vh;
  }
  .section2-gallery2 {
    width: 55%;
    top: 30vh;
  }
  .section2-gallery3 {
    width: 55%;
    top: 50vh;
  }
}

@media (max-width: 600px) and (min-width: 500px) {
  .showcasemainMobile-section2 {
    height: 110vh;
  }
  .section2-gallery1 {
    width: 50%;
  }
  .section2-text {
    width: 50%;
    top: 25vh;
    & p {
      width: 100%;
      font-size: 1.4rem;
      margin: 3%;
    }
  }
  .section2-gallery2 {
    width: 50%;
    top: 50vh;
  }
  .section2-gallery3 {
    width: 50%;
    top: 75vh;
  }
}

@media (max-width: 500px) and (min-width: 400px) {
  .showcasemainMobile-section2 {
    height: 90vh;
  }
  .section2-gallery1 {
    width: 50%;
  }
  .section2-text {
    width: 50%;
    top: 20vh;
    & p {
      width: 100%;
      font-size: 1.4rem;
      margin: 3%;
    }
  }
  .section2-gallery2 {
    width: 50%;
    top: 40vh;
  }
  .section2-gallery3 {
    width: 50%;
    top: 60vh;
  }
}

@media (max-width: 500px) and (min-width: 400px) and (min-height: 900px) {
  .showcasemainMobile-section2 {
    height: 45vh;
  }
  .section2-gallery1 {
    width: 50%;
  }
  .section2-text {
    width: 50%;
    top: 10vh;
    & p {
      width: 100%;
      font-size: 1.4rem;
      margin: 3%;
    }
  }
  .section2-gallery2 {
    width: 50%;
    top: 20vh;
  }
  .section2-gallery3 {
    width: 50%;
    top: 30vh;
  }
}

@media (max-width: 400px) and (min-width: 300px) {
  .showcasemainMobile-section2 {
    height: 50vh;
  }
  .section2-gallery1 {
    width: 50%;
  }
  .section2-text {
    width: 50%;
    top: 10vh;
    & p {
      width: 100%;
      font-size: 1.4rem;
      margin: 3%;
    }
  }
  .section2-gallery2 {
    width: 50%;
    top: 20vh;
  }
  .section2-gallery3 {
    width: 50%;
    top: 30vh;
  }
}

@media (max-width: 900px) and (min-width: 700px) and (max-height: 400px) {
  .showcasemainMobile-section2 {
    height: 150vh;
  }
  .section2-gallery1 {
    width: 50%;
  }
  .section2-text {
    max-width: none;
    width: 50%;
    top: 40vh;
    & p {
      width: 100%;
      font-size: 1.8rem;
      margin: 3%;
    }
  }
  .section2-gallery2 {
    width: 50%;
    top: 70vh;
  }
  .section2-gallery3 {
    width: 50%;
    top: 100vh;
  }
}

@media (max-width: 900px) and (min-width: 800px) and (max-height: 400px) {
  .showcasemainMobile-section2 {
    height: 250vh;
  }
  .section2-gallery1 {
    width: 50%;
  }
  .section2-text {
    max-width: none;
    width: 50%;
    top: 60vh;
    & p {
      width: 100%;
      font-size: 1.8rem;
      margin: 3%;
    }
  }
  .section2-gallery2 {
    width: 50%;
    top: 115vh;
  }
  .section2-gallery3 {
    width: 50%;
    top: 170vh;
  }
}

@media (max-width: 900px) and (min-width: 600px) and (max-height: 600px) {
  .showcasemainMobile-section2 {
    height: 250vh;
  }
  .section2-gallery1 {
    width: 50%;
  }
  .section2-text {
    max-width: none;
    width: 50%;
    top: 60vh;
    & p {
      width: 100%;
      font-size: 1.8rem;
      margin: 3%;
    }
  }
  .section2-gallery2 {
    width: 50%;
    top: 115vh;
  }
  .section2-gallery3 {
    width: 50%;
    top: 170vh;
  }
}

@media (max-width: 900px) and (min-width: 600px) and (max-height: 500px) {
  .showcasemainMobile-section2 {
    height: 260vh;
  }
  .section2-gallery1 {
    width: 50%;
  }
  .section2-text {
    max-width: none;
    width: 50%;
    top: 60vh;
    & p {
      width: 100%;
      font-size: 1.8rem;
      margin: 3%;
    }
  }
  .section2-gallery2 {
    width: 50%;
    top: 115vh;
  }
  .section2-gallery3 {
    width: 50%;
    top: 170vh;
  }
}

@media (max-width: 600px) and (min-width: 500px) and (max-height: 500px) {
  .showcasemainMobile-section2 {
    height: 140vh;
  }
  .section2-gallery1 {
    width: 50%;
  }
  .section2-text {
    max-width: none;
    width: 50%;
    top: 30vh;
    & p {
      width: 100%;
      font-size: 1.8rem;
      margin: 3%;
    }
  }
  .section2-gallery2 {
    width: 50%;
    top: 60vh;
  }
  .section2-gallery3 {
    width: 50%;
    top: 90vh;
  }
}
