.error-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  position: absolute;
  top: 0;
}

.error-container::before,
.error-container::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0) 35%,
      rgba(0, 0, 255, 0.3) 45%,
      rgba(0, 0, 255, 0.3) 55%,
      rgba(0, 0, 0, 0) 65%
    ),
    linear-gradient(
      135deg,
      rgba(0, 0, 0, 0) 35%,
      rgba(0, 0, 255, 0.3) 45%,
      rgba(0, 0, 255, 0.3) 55%,
      rgba(0, 0, 0, 0) 65%
    );
  transform: translate(-50%, -50%);
}

.error-container::after {
  transform: rotate(90deg) translate(-50%, -50%);
}

.error-text-container {
  width: 90%;
  max-height: 90%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-all;
  border-radius: 15px;
  backdrop-filter: blur(5px);
  overflow: hidden;
  color: white;
}

.error-text-container h1,
.error-text-container h2,
.error-text-container p {
  margin: 10px;
}

.error-text-container h1 {
  font-size: 50px;
  color: white;
  text-shadow: 3px 3px 3px black;

  @media (max-width: 340px) {
    font-size: 40px;
  }
}

.error-text-container h2 {
  font-size: 30px;
  color: white;
  margin-bottom: 20px;
  text-shadow: 2px 2px 2px black;

  @media (max-width: 340px) {
    font-size: 20px;
  }
}

.error-text-container p {
  font-size: 16px;
  font-weight: 500;
  color: white;
  word-break: keep-all;
  text-align: center;

  @media (max-width: 340px) {
    font-size: 13px;
  }
}

.error-text-container br {
  display: none;

  @media (max-width: 500px) {
    display: block;
  }
}

.error-text-container a {
  color: #1c0093;
}

.error-container button {
  background-color: transparent;
  border: 1.5px solid white;
  border-radius: 50px;
  color: white;
  padding: 5px 10px;
  margin: 10px;
  cursor: pointer;
}

.error-container button:hover {
  border: 1.5px solid #1c0093;
  color: #1c0093;
}
