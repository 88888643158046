.ActivityDetail-container {
  position: relative;
  display: flex;
  justify-content: start;
  align-content: center;
  width: 100%;
  height: auto;
  padding-top: 90px;
  min-height: 100vh;

  @media (max-width: 700px) {
    flex-direction: column;
  }
}

.ActivityDetail-container .logo {
  position: absolute;
  top: 15px;
  left: 40px;
}

.ActivityDetail-titles {
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: auto;
  padding-left: 20px;

  @media (max-width: 700px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    padding-bottom: 20px;
  }
}

.ActivityDetail-titles button {
  margin: 10px;
  border-style: none;
  background-color: transparent;
  font-size: 1.3rem;
  font-weight: 600;
  cursor: pointer;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media (max-width: 700px) {
    font-size: 1.5rem;
  }
}

.year-dropdown {
  margin: 16px;
  padding: 1px 5px;
  color: white;
  border: 1.5px solid white;
  border-radius: 7px;
  background-color: #c0ceff;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  cursor: pointer;

  @media (max-width: 700px) {
    display: none;
  }
}

.year-dropdown option {
  color: #c0ceff;
  background-color: white;
}

.ActivityDetail-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ActivityDetail-top-mobile {
  width: 100%;
  display: none;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  position: relative;

  @media (max-width: 700px) {
    display: flex;
  }
}

.year-dropdown.mobile {
  display: none;
  @media (max-width: 700px) {
    display: block;
  }
}

.ActivityDetail-container .back-button {
  color: white;
  font-size: 2.3rem;
  cursor: pointer;
}

.ActivityDetail-container .back-button.pc {
  margin-bottom: 20px;

  @media (max-width: 700px) {
    display: none;
  }
}
