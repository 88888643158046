.joinquipu-container {
  width: 100%;
  position: relative;
  background: linear-gradient(-50deg, #6499e9, #e4f1ff);
}

.joinquipu-logo {
  position: absolute;
  top: 15px;
  left: 40px;
  z-index: 5000;

  @media (max-width: 900px) {
    top: 5px;
    left: 15px;
  }
}

.joinquipu-content {
  padding-top: 80px;
  width: 100%;
  height: 100%;
  margin: 0;
  color: black;
  font-size: 14px;
  line-height: 1.5em;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.joinquipu-content p {
  font-family: 'LINESeedKR';
}

.joinquipu-content h2 {
  font-family: 'JalnanGothic';
  font-size: 2.2rem;
  color: #1c0093;
  letter-spacing: 1px;
  margin: 0px;
}

.joinquipu-content h3 {
  font-size: 1.5rem;
  color: #59689f;
  letter-spacing: 1px;
  margin: 40px;
}

.apply-title {
  width: 60%;
  max-width: 300px;
  border-top: 1.5px solid #666666;
  margin-top: 40px;
}

.joinquipu-content h4 {
  font-family: 'JalnanGothic';
  font-size: 1.7rem;
  text-align: center;
  color: #1c0093;
  letter-spacing: 1px;
  margin: 25px;
}

.join-notice__icon {
  display: flex;
  flex-direction: column;
  width: 93%;
  max-width: 600px;
  color: #898989;
  border: 1px solid #1f2020;
  border-radius: 0.5em;
  background-color: #2b2b2b;
  box-shadow: 1px 1px 3px 1px #00000040;
  font-size: 13px;
}

.join-notice__icon--top1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 18px;
  border-top-right-radius: 0.5em;
  border-top-left-radius: 0.5em;
  background-color: #1f2020;
}

.join-notice__icon--top1 p {
  margin: 0;
}

.join-notice__icon--top2 {
  width: 100%;
  height: 16px;
  background-color: #3e3e3e;
}

.join-notice__icon--body {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  white-space: nowrap;
  text-align: center;
  color: white;
}

.join-notice__icon--body h1 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.7;
}

.join-notice__icon--body p {
  font-family: 'NEXON Lv2 Gothic';
  margin: 0;
  font-size: 0.9rem;
  font-weight: 900;
  letter-spacing: 0.8px;
}

.join-notice__icon--body span {
  color: #06da22;
}

.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px;

  @media (max-width: 450px) {
    padding: 0 12px;
  }
}

.form-field {
  width: 90%;
  max-width: 600px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-container p {
  font-size: 1rem;
  margin: 10px 5px;
  text-align: start;
  width: 100%;
  font-weight: 900;
}

.form-container input,
.form-container textarea {
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  background-color: rgb(255, 255, 255, 0.6);
  border: none;
  resize: none;
  line-height: 1.5;
  font-family: 'LINESeedKR';
  box-sizing: border-box;

  @media (max-width: 600px) {
    padding: 12px;
  }
  @media (max-width: 450px) {
    padding: 10px;
  }
}
.form-radio-box {
  width: 100%;
  display: flex;
  justify-content: start;
  gap: 12px;
}

.form-radio-item {
  cursor: pointer;
  padding: 1% 2.5%;
  border-radius: 25px;
  font-weight: 600;
  font-size: 0.9rem;
  color: #7091f5;
  background-color: white;
  border: 2px solid #7091f5;
  display: flex;
  align-items: center;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.form-radio-item.selected {
  background-color: #7091f5;
  color: white;
}

.account-number {
  color: yellow;
  text-decoration-line: underline;
  cursor: pointer;
}

.apply-button {
  width: 130px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #dfe7ff;
  border-radius: 12px;
  font-size: 20px;
  font-weight: 600;
  color: #1c0093;
  border: solid 2px #1c0093;
  margin-top: 50px;
}

.apply-button.button-disabled {
  color: grey;
  border: solid 2px grey;
}

.faq-title {
  font-family: 'JalnanGothic';
  font-size: 1.9rem;
  letter-spacing: 1px;
  margin-top: 80px;
}

.faq {
  width: 80%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
}

.question {
  width: 100%;
  margin-bottom: 15px;
  padding: 20px 30px;
  border-radius: 30px;
  background-color: #ebf0ff;
  cursor: pointer;

  @media (max-width: 500px) {
    padding: 15px 20px;
    width: 90vw;

    .emoji {
      display: none;
    }
  }
}

.question-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 15px;
  font-weight: 700;
}

.question-title {
  font-weight: bold;
  word-break: keep-all;
  font-size: 1rem;
}

.question.expanded .expanded-content {
  display: block;
  font-size: 0.95rem;
  margin-top: 15px;
  letter-spacing: 0.3px;
  word-break: keep-all;
  line-height: 1.3;
}

.expanded-content {
  display: none;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
  transform: translate(-50%, -50%);
  min-width: 410px;
  width: auto;
  height: 160px;
  display: flex;
  flex-direction: column;
  color: #1f2020;
  border: 1px solid #cacaca;
  border-radius: 0.5em;
  background-color: #ffffff;
  box-shadow: 1px 1px 5px 1px #b7b7b7;
  font-size: 1.5em;

  @media (max-width: 500px) {
    min-width: 258px;
    width: 75%;
    height: auto;
  }
}

.popup__icon--top {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 30px;
  border-top-right-radius: 0.5em;
  border-top-left-radius: 0.5em;
  background: linear-gradient(0deg, #dddddd, #ffffff);
  @media (max-width: 500px) {
    height: 27px;
  }
  @media (max-width: 400px) {
    height: 23px;
  }
}

.popup__icon--body {
  display: flex;
  align-items: center;
  margin-top: 7px;
  word-break: keep-all;
}

.popup__icon--body img {
  width: 90px;
  height: auto;
  margin-right: 20px;

  @media (max-width: 500px) {
    margin-right: 12px;
    width: 80px;
  }
  @media (max-width: 400px) {
    width: 60px;
  }
}

.popup_content {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 7px 0 23px 0;
}

.popup_message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 15px;
}

.head-message {
  font-weight: 700;
  text-align: start;
  font-size: 1.3rem;
}

.sub-message {
  margin: 3px 0 6px 0;
  text-align: start;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.3;
}

.popup__button-container {
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup button {
  width: 90px;
  height: 27px;
  font-size: 1rem;
  color: #ffffff;
  border: solid 1px #3c8aef;
  border-radius: 8px;
  background: linear-gradient(0deg, #3c8aef, #78bdf4);
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  @media (max-width: 500px) {
    width: 75px;
    height: 22px;
  }
  @media (max-width: 400px) {
    width: 60px;
    height: 18px;
  }
}

.popup button:hover {
  box-shadow: 0 0 3px 1.5px #78bdf4;
}

.joinquipu-checkbox {
  width: 90%;
  max-width: 600px;
  background-color: rgb(255, 255, 255, 0.3);
  border-radius: 6px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.joinquipu-checkbox #checkbox-label {
  font-size: 0.9rem;
  word-break: keep-all;
}

#checkbox-input {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.joinquipu-container .footer {
  background: transparent;
}

.joinquipu-container .footer * {
  color: #26355d;
}

.joinquipu-container .footer .footer-logo h1 {
  background: linear-gradient(to right, #26355d, #0f6292);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-overlay img {
  width: 100px;
  height: 100px;
}
