.recommend-site {
  width: 100%;
  padding: 100px 0;
  background-color: #cbddff;
}

.recommend-site__title {
  height: 50px;
  font-size: 2.2rem;
  font-weight: 600;
  color: #11324d;
  margin-left: 3%;

  @media screen and (max-width: 850px) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 5%;
  }
}

.recommend-site__icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  height: 150px;
  background-color: white;
  color: #626262;
  border: 1px solid #cacaca;
  border-radius: 0.5em;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  position: relative;
  z-index: 100;

  @media (max-width: 1050px) {
    margin: 50px;
  }
  @media screen and (max-width: 800px) {
    width: 230px;
    height: 140px;
    margin: 40px;
  }
  @media screen and (max-width: 300px) {
    width: 200px;
    height: 120px;
  }
}

.recommend-site__icon:hover {
  transform: scale(1.1);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2), 0 4px 6px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  @media (max-width: 800px) {
    transform: scale(1.1);
  }
}

.recommend-site__icon--top1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 20px;
  border-top-right-radius: 0.5em;
  border-top-left-radius: 0.5em;
  background-color: #f8f8f8;
}

.recommend-site__icon--top2 {
  width: 100%;
  height: 7px;
  background-color: #fff9f9;
}

.recommend-site__icon--body {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  margin: 10%;
  position: relative;
  word-break: keep-all;

  @media screen and (max-width: 300px) {
    justify-content: start;
    align-items: start;
  }
}

.recommend-site__icon--body img {
  border-radius: 10px;
  width: 60px;
  height: 60px;
}

.recommend-site__box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
  overflow-x: auto;
  min-width: calc(100% - 80px);

  @media (max-width: 1050px) {
    flex-direction: row;
    height: auto;
  }
  @media (max-width: 800px) {
    display: none;
  }
}

.recommend-site__box::-webkit-scrollbar {
  display: none;
}

.recommend-site__box--top {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 40vh;
  max-width: 1300px;
  position: relative;
  z-index: 1;

  @media (max-width: 1050px) {
    width: auto;
    height: auto;
    flex-direction: column;
  }
  @media (max-width: 800px) {
    display: none;
  }
}

.recommend-site__box--bottom {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 40vh;
  max-width: 900px;
  position: relative;
  z-index: 1;

  @media (max-width: 1050px) {
    width: auto;
    height: auto;
    flex-direction: column;
  }
  @media (max-width: 800px) {
    display: none;
  }
}

.recommend-site-box-mobile {
  display: none;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  @media (max-width: 800px) {
    display: flex;

    .recommend-site__icon:nth-child(odd) {
      transform: translateX(-15vw);
    }

    .recommend-site__icon:nth-child(even) {
      transform: translateX(15vw);
    }
  }
  @media (max-width: 500px) {
    display: flex;

    .recommend-site__icon:nth-child(odd) {
      transform: translateX(-10vw);
    }

    .recommend-site__icon:nth-child(even) {
      transform: translateX(10vw);
    }
  }
}
