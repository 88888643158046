.footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 200px;
  padding: 20px;
  line-height: 1.7;
  background: linear-gradient(to bottom, #cbddff 0%, #ebf0ff 30%);

  @media (max-width: 1050px) {
    height: 250px;
    padding: 15px;
    padding-top: 50px;
  }
}

.footer * {
  color: #596f8f;
  font-family: 'NEXON Lv2 Gothic';
  font-weight: 900;
  font-size: 0.9rem;
}

.footer-contact {
  width: 33%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  @media (max-width: 1050px) {
    display: none;
  }
}

.footer-contact h5 {
  margin: 0;
  margin-bottom: 10px;
}

.footer-contact a {
  text-decoration: none;
}

.footer-contact a:hover {
  color: grey;
}

.footer-logo {
  width: 33%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 1050px) {
    width: auto;
    justify-content: center;
  }
}

.footer-copyright {
  font-size: 0.7rem;
}

.footer-logo .logo {
  width: 150px;
  margin: 20px;
  left: 6px;
  @media (max-width: 1050px) {
    margin: 0px;
  }
}

.footer-logo h1 {
  font-size: 2.3rem;
}

.footer-logo .logo h1 {
  background: linear-gradient(to right, #b3c2d7, #596f8f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer-icon {
  width: 33%;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: end;
}

.footer-icon .icon {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  @media (max-width: 1050px) {
    width: 26px;
    height: 26px;
  }
}

.footer-icon .icon:hover {
  transform: scale(1.1);
}

.footer-icon.pc {
  @media (max-width: 1050px) {
    display: none;
  }
}

.footer-icon.mobile {
  display: none;
  width: auto;
  height: auto;

  @media (max-width: 1050px) {
    display: flex;
    margin: 10px;
  }
}
