:root {
  --vh: 100%;
}

html {
  scroll-behavior: smooth;
}

#root,
body,
html {
  width: 100%;
  @media (max-width: 700px) {
    font-size: 15px;
  }
  @media (max-width: 600px) {
    font-size: 14px;
  }
  @media (max-width: 500px) {
    font-size: 13px;
  }
  @media (max-width: 400px) {
    font-size: 12px;
  }
}

body {
  margin: 0px;
  font-family: 'LINESeedKR';
  background-color: #c0ceff;
}

div,
nav {
  box-sizing: border-box;
}

p {
  margin: 0px;
}

.blank-box {
  width: 100%;
  height: 170px;

  @media (max-width: 1050px) {
    height: 100px;
  }
}

@font-face {
  font-family: 'LINESeedKR';
  src: url('./fonts/LINESeedKR-Bd.woff2') format('woff2'),
    url('./fonts/LINESeedKR-Bd.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'LINESeedKR';
  src: url('./fonts/LINESeedKR-Rg.woff2') format('woff2'),
    url('./fonts/LINESeedKR-Rg.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'LINESeedKR';
  src: url('./fonts/LINESeedKR-Th.woff2') format('woff2'),
    url('./fonts/LINESeedKR-Th.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'JalnanGothic';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_231029@1.1/JalnanGothic.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NEXON Lv2 Gothic';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@2.1/NEXON Lv2 Gothic.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}

/* Orbitron, Lora, Source Serif, Playfair Display */
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Orbitron:wght@400..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Source+Serif+4:ital,opsz,wght@0,8..60,200..900;1,8..60,200..900&display=swap');

@import url('https://fonts.googleapis.com/css?family=Black+Han+Sans:400');

@import url('https://fonts.googleapis.com/css2?family=Jua&display=swap');

@font-face {
  font-family: 'ONE-Mobile-POP';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2105_2@1.0/ONE-Mobile-POP.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap')
    format('woff');
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Amaranth:ital,wght@0,400;0,700;1,400;1,700&family=Jua&display=swap');

@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff')
    format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'RIDIBatang';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.0/RIDIBatang.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
  unicode-range: U+1100-11FF, U+3130-318F, U+AC00-D7A3;
}
