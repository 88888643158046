.showcasemain-house {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  position: relative;
  background-image: linear-gradient(
    to right top,
    #f5f5f5,
    #f7f7f7,
    #f8f9f8,
    #fafafa,
    #fcfcfc,
    #f9f8fb,
    #f5f5fb,
    #f1f1fa,
    #e5e7f7,
    #d7ddf4,
    #c8d4f1,
    #b7cbee
  );
  display: flex;
  flex-direction: column;
  align-items: center;
}

.showcasemain-house * {
  font-family: 'RIDIBatang', 'Lora';
}

.showcasemain-house .back-button {
  font-size: 2rem;
  color: #333333;
  position: absolute;
  left: 20px;
  top: 90px;
  cursor: pointer;
}

.showcasemain-container {
  max-width: 1150px;
  width: 100%;
  height: 100%;
}

.showcasemain-nav {
  height: fit-content;
  border-bottom: 1px solid #0000001a;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.showcasemain-logo {
  margin: 0.5% 3% 1% 3%;
  font-style: italic;
  font-size: 3rem;
  font-weight: 700;
  text-align: left;
}

.showcasemain-logo a {
  text-decoration: none;
  color: #333333;
}

.showcasemain {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 21px;
  width: 80%;
  height: 13%;
  border: 1px solid transparent;
}

@media (max-width: 900px) {
  .section1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 21px;
    width: 86%;
    height: 13%;
    border: 1px solid #0c0b0b1a;
  }
}

.section2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 90%;
  aspect-ratio: 1.5/1;
  margin-bottom: 50px;
}
/* 
.showcasemain-list {
  padding: 1%;
  margin: 0 5% 0 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 12%;
  height: 60px;
  font-size: 1.2rem;
  color: #333333;
  border-right: 4px solid #333333;
  border-left: 4px solid #333333;
  clip-path: polygon(10% 0%, 90% 0%, 100% 50%, 90% 100%, 10% 100%, 0% 50%);
  transition: font-size 0.5s ease;
  }
  
  .showcasemain-list:hover {
    font-size: rem;
  } */

.btn-hover.color-8 {
  color: whitesmoke;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 5% 0 2%;
  background-image: linear-gradient(to right, #29323c, #485563, #2b5876, #4e4376);
  box-shadow: 0 4px 15px 0 rgba(45, 54, 65, 0.75);
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.buttons {
  margin: 10%;
  text-align: center;
}

.btn-hover {
  width: 200px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 20px;
  height: 55px;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}

.gallery {
  min-width: 400px;
  width: 50%;
  aspect-ratio: 1.5/1;
  border: 1px solid #0c0b0b1a;
  background-color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.gallery1 {
  width: 95%;
  height: 95%;
  background-color: whitesmoke;
  border: 1px solid #0c0b0b1a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery1 img {
  object-fit: cover;
  width: 92%;
  aspect-ratio: 1.5/1;
  height: 92%;
}

.gallery2 {
  width: 95%;
  height: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.gallery2 h3 {
  position: absolute;
  top: 0;
  font-size: 1.3rem;
  text-align: center;
  word-break: keep-all;
  color: black;
  padding: 3px;
}

.gallery3 {
  box-sizing: border-box;
  width: 47%;
  height: 46%;
  margin: 1%;
  background-color: whitesmoke;
  border: 1px solid #0c0b0b1a;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery3 img {
  width: 90%;
  height: 90%;
  object-fit: cover;
}

.gallery3.text {
  background-color: transparent;
  border: none;
}

.gallery3.text h4 {
  margin-top: 0;
  text-align: center;
  text-decoration-line: underline;
  text-decoration-color: white;
  text-decoration-thickness: 2px;
  text-underline-offset: 3px;
  font-size: 2.5rem;
  margin-bottom: 1%;
  white-space: nowrap;
  position: relative;
  background-color: rgb(0, 0, 0, 0.9);
  color: white;
  padding: 0 10px;
}

.gallery4 {
  width: 95%;
  height: 95%;
  border: 1px solid #0c0b0b1a;
  background-color: whitesmoke;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery4 img {
  width: 92%;
  height: 92%;
  object-fit: cover;
}

.view-button {
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2%;
  padding: 7px 15px;
  border-radius: 29px;
  border: 1.5px solid #333333;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  color: black;
  font-size: 1.2rem;
  font-weight: bold;
  transition: all 0.5s ease;
}

.view-button:hover {
  background-color: #333333;
  color: white;
}

.mobile-view {
  width: 100%;
  background-color: rgba(254, 251, 255, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
